import { MdOutlineDesignServices } from 'react-icons/md';

import { ControllerDisplayModeSupport } from './types';
import { DisplayMode } from '@rta/rta-blueprint-component';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { useSubscription } from '@rta/rta-blueprint-library';
import { Feature, FeatureHoookParams } from '..';

type UseDisplayManufacturingModeFeatureParams = FeatureHoookParams;
export const useDisplayManufacturingModeFeature = (_params: UseDisplayManufacturingModeFeatureParams = {}): Feature<boolean> => {
	const design = useDesign();
	const controller = design[0] as (typeof design[0]) & ControllerDisplayModeSupport;

	const controllerSupportsFeature = (controller?.navigation?.displayMode ?? null) !== null;
	const inManufacturingView = controller?.navigation?.displayMode === DisplayMode.Manufacturing;
	const inOverheadView = controller?.navigation?.displayMode === DisplayMode.Overhead;
	const inOverheadManufacturingView = controller?.navigation?.displayMode === DisplayMode.OverheadManufacturing;

	useSubscription(controller, false, [['navigation-display-mode', '*']]);

	const [disabled, tooltip] = (() => {
		if (!controllerSupportsFeature) return [true, lang.navigation.tooltip.disabledNotSupportCurrentView];
		return [false, lang.navigation.tooltip.manufacturingMode];
	})();

	return {
		name: 'manufacturing-view',
		label: lang.navigation.label.manufacturingMode,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <MdOutlineDesignServices color={inManufacturingView || inOverheadManufacturingView ? 'green' : 'unset'} {...props} />,
		disabled,
		value: inManufacturingView,
		hidden: false,
		handler: () => {
			if (controllerSupportsFeature) {
				if (inManufacturingView) {
					// revert to saved displayMode
					controller.navigation.displayMode = DisplayMode.Panel;
				} else if (inOverheadView) {
					controller.navigation.displayMode = DisplayMode.OverheadManufacturing;
				} else {
					controller.navigation.displayMode = DisplayMode.Manufacturing;
				}
			}
			// console.log('viewMode', viewMode);
		}
	};
};
