type ReleaseInfo = {
	release: string;
	build: string;
	commit: string;
}

const releaseInfo: ReleaseInfo = {
	release: '__RELEASE__',
	build: '20241010.1',
	commit: '576492b'
};

export { releaseInfo };

